import {Backdrop} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {loadRolesAction, loadUsersAction} from "../../pages/admin_user/admin_user_actions";
import {selectData, selectLoadStatus, selectRoles} from "../../pages/admin_user/admin_user_selectors";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {isPending, isResolved} from "../../redux/utils/status";
import {sortArrayOfObjectsByKey} from "../../utils/sort_array_of_objects_by_key";
import {loadEmployeesAction} from "../employees/employees_actions";
import {selectFeSettings} from "../fe_settings/fe_settings_selectors";
import CommonSelector from "../shared/common_selector/common_selector";
import Loading from "../shared/loading/loading";
import useStyles from "./admin_user_canvas.styles";
import UserTable from "./components/user_table";

const AdminUserCanvas = ({onEdit, saved}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const users = useSelector(selectData);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const loadStatus = useSelector(selectLoadStatus);
    const rolesRedux = useSelector(selectRoles);
    const {excludedRoles} = useSelector(selectFeSettings);

    const [roles, setRoles] = useState([]);
    const [status, setStatus] = useState(); // "active", "deactivated" or "all"
    const [page, setPage] = useState(0);

    useEffect(() => {
        dispatch(loadEmployeesAction(organizationId));
    }, [organizationId]);

    useEffect(() => {
        if (organizationId) {
            const params = {
                organizationId,
                roles: roles ? roles : undefined,
                status: status ? status : undefined
            };
            dispatch(loadUsersAction(params, organizationId));
            dispatch(loadRolesAction());
        }
    }, [organizationId, roles, status, saved]);

    const handleChangeRoles = (e) => {
        setRoles(e.target.value);
        setPage(0);
    };
    const handleResetRoles = () => {
        setRoles([]);
        setPage(0);
    };
    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
        setPage(0);
    };
    const handleResetStatus = () => {
        setStatus("");
        setPage(0);
    };

    const handleReset = () => {
        setRoles([]);
        setStatus();
    };

    const rolesList = rolesRedux
        .filter((role) => !excludedRoles?.includes(role.name))
        .map((role) => ({
            label: t(`Roles.${role.name}`, role.name),
            value: role.name
        }))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));
    const rolesSelect = (
        <CommonSelector
            items={rolesList}
            multiple
            placeholder={t("AdminUserCanvas.selectRoles")}
            styles={{input: classes.input}}
            title={t("AdminUserCanvas.roles")}
            value={roles}
            onChange={handleChangeRoles}
            onReset={handleResetRoles}
        />
    );
    const statusList = [
        {label: t("App.active"), value: "active"},
        {label: t("App.deactivated"), value: "deactivated"}
    ];
    const statusSelect = (
        <div className={classes.ml1}>
            <CommonSelector
                items={statusList}
                placeholder={t("AdminUserCanvas.selectStatus")}
                styles={{input: classes.input}}
                title={t("AdminUserCanvas.status")}
                value={status}
                onChange={handleChangeStatus}
                onReset={handleResetStatus}
            />
        </div>
    );

    const noResult = (
        <div className={classes.ml1}>
            <div className={classes.info}>{t("AdminUserCanvas.noResult")}</div>
            <div
                className={classes.clickText}
                role={"button"}
                tabIndex={0}
                onClick={handleReset}
                onKeyDown={(e) => e.key === "Enter" && handleReset()}
            >
                {t("AdminUserCanvas.reset")}
            </div>
        </div>
    );
    // Set table padding: header (128) + page padding (100) + pagination (60)
    const tableTopBottomPadding = 100 + 128 + 60; // in pixel
    return (
        <div className={classes.canvasRoot}>
            {isPending(loadStatus) && (
                <Backdrop className={classes.loading} open>
                    <Loading />
                </Backdrop>
            )}
            <div className={classes.filterWrapper}>
                <div className={classes.filterRow}>
                    {rolesSelect}
                    {statusSelect}
                </div>
            </div>
            {isResolved(loadStatus) && users && !users.length && noResult}
            <div className={classes.tableWrapper}>
                <UserTable page={page} tableTopBottomPadding={tableTopBottomPadding} users={users} onEdit={onEdit} onSetPage={setPage} />
            </div>
        </div>
    );
};
AdminUserCanvas.propTypes = {
    onEdit: PropTypes.func.isRequired,
    saved: PropTypes.bool
};
export default AdminUserCanvas;
