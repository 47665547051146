// @ts-check
/**
 * @fileoverview redux selectors for account data
 */

/**
 * @typedef AccountState
 * @type {Object}
 * @property {string|null} error
 * @property {boolean} isPasswordUnequal
 * @property {Status} status
 */

/**
 * Selects the account slice of the store
 *
 * @param {*} state
 * @return {AccountState}
 */
const selectAccountState = (state) => state.account;

/**
 * Selects the error key of the account slice of the store
 *
 * @param {*} state
 * @return {string|null}
 */
const selectError = (state) => selectAccountState(state).error;

/**
 * Selects the status of the account slice of the store
 *
 * @param {*} state
 * @return {boolean}
 */
const selectErrorIsPasswordUnequal = (state) => selectAccountState(state).isPasswordUnequal;

/**
 * Selects the status key of the account slice of the store
 *
 * @param {*} state
 * @return {Status}
 */
const selectStatus = (state) => selectAccountState(state).status;

export {selectError, selectStatus, selectErrorIsPasswordUnequal};
