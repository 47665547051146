/**
 * @fileoverview redux reducer for room blocker
 */

import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./account_action_types";

const initialState = {
    status: STATUS.IDLE,
    error: null,
    isPasswordUnequal: false
};

const handleUpdateFailure = (state, {error, isPasswordUnequal}) => ({
    ...state,
    status: STATUS.REJECTED,
    error,
    isPasswordUnequal
});

const handleUpdateRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

const handleUpdateSuccess = (state) => ({
    ...state,
    status: STATUS.RESOLVED,
    error: null,
    isPasswordUnequal: false
});

const handlers = {
    [ActionTypes.UPDATE_REQUEST]: handleUpdateRequest,
    [ActionTypes.UPDATE_SUCCESS]: handleUpdateSuccess,
    [ActionTypes.UPDATE_FAILURE]: handleUpdateFailure
};

export default createReducer(initialState, handlers);
