// @ts-check
/**
 * @fileoverview redux actions for user account page
 */
/** @typedef {import('react-hook-form').UseFormSetError<any>} UseFormSetError */
/** @typedef {import('react-hook-form').UseFormReset<any>} UseFormReset */

import {authUser, authUserFailureAction} from "../../redux/actions/index";
import ActionTypes from "./account_action_types";
import {updateName, updatePassword} from "./account_api";

const updateRequestAction = () => ({
    type: ActionTypes.UPDATE_REQUEST
});

const updateSuccessAction = () => ({
    type: ActionTypes.UPDATE_SUCCESS
});

const updateFailureAction = (error, isPasswordUnequal) => ({
    type: ActionTypes.UPDATE_FAILURE,
    error,
    isPasswordUnequal
});

/**
 * update the user's password
 *
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.passwordOld
 * @param {String} params.passwordNew
 * @param {UseFormSetError} params.setError The error setter from RHF
 * @param {UseFormReset} params.reset The error resetter from RHF
 * @return {function}
 */
function updatePasswordAction({id, passwordOld, passwordNew, setError, reset}) {
    return function (dispatch) {
        dispatch(updateRequestAction());

        updatePassword(id, passwordOld, passwordNew)
            .then(({data}) => {
                dispatch(updateSuccessAction());
                reset();
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "update password error"}));
                } else if (error.response.data.msg === "Password does not match") {
                    dispatch(updateFailureAction(error.message, true));
                    setError("passwordOld", {type: "invalid", message: "Account.old_password_wrong"});
                } else {
                    dispatch(updateFailureAction(error.message, false));
                    setError("passwordOld", {type: "invalid", message: "Account.error"});
                }
            });
    };
}

/**
 * update first and last name of a user
 * @param {String} email
 * @param {String} firstName
 * @param {String} lastName
 * @return {function}
 */
function updateNameAction(email, firstName, lastName) {
    return function (dispatch) {
        dispatch(updateRequestAction());

        updateName(email, firstName, lastName)
            .then(({data}) => {
                dispatch(authUser()); // in order to reflect initials, fetch userObject
                dispatch(updateSuccessAction());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "update name error"}));
                } else {
                    dispatch(updateFailureAction(error.message, false));
                }
            });
    };
}

export {updatePasswordAction, updateNameAction};
