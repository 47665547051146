import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    button: {
        fontSize: "0.688rem",
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "uppercase"
    },
    disabled: {
        fontSize: "0.688rem",
        color: theme.palette.primary.grey2,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "uppercase"
    }
}));
