import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    title: {
        "& h2": {
            fontSize: "1.125rem",
            letterSpacing: "-0.15px",
            lineHeight: "1.5rem",
            fontWeight: theme.typography.fontWeightLight,
            color: theme.palette.grey[900],
            textTransform: "uppercase"
        }
    },
    icon: {
        top: "0.4rem",
        position: "relative",
        marginRight: theme.spacing("xs")
    }
}));
