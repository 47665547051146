/**
 * @fileoverview redux action types for account infos
 */

const ActionTypes = {
    UPDATE_REQUEST: "room_planner/UPDATE_REQUEST",
    UPDATE_SUCCESS: "room_planner/UPDATE_SUCCESS",
    UPDATE_FAILURE: "room_planner/UPDATE_FAILURE"
};

export default ActionTypes;
