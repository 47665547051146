// @ts-check
import PropTypes from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {sortWithLocale} from "../../../utils/sort_with_locale";
import {selectAllStandardNamesObject} from "../../private_data/private_data_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import DataTable from "../../shared/data_table/data_table";

/**
 * @typedef {Object} Disciplines
 * @property {String} id healthcareServiceId that is used to translate the name
 * @property {String} name original name of healthcareService provided by customer, used as a fallback
 * @property {UpdatedBy} updatedBy
 * @property {String} [updatedAt]
 * @property {Array<String>} locations array of locationIds
 * @property {Array<String>} practitionerIds
 */
/**
 * DisciplinesTable component
 * @param {Object} props
 * @param {Array<Disciplines>} props.disciplines
 * @param {Number} props.tableTopBottomPadding
 * @param {Number} props.page=0
 * @param {Function} props.onSetPage
 * @return {React.ReactElement}
 */
const DisciplinesTable = ({disciplines, tableTopBottomPadding, page = 0, onSetPage}) => {
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);

    // Redux
    const roomInfos = useSelector(selectRoomInfos);
    const allNamesObject = useSelector(selectAllStandardNamesObject({type: "practitioner"}));

    let surgeons = [];
    for (const item of disciplines) {
        surgeons = surgeons.concat(item.practitionerIds);
    }

    const disciplinesFormatted = disciplines.map((row) => {
        const lastUpdate =
            row.updatedBy && row.updatedAt
                ? t("AdminDisciplinesCanvas.lastUpdateText", {
                      date: formatFromISO(row.updatedAt, DATE_FORMATS.DATE),
                      time: formatFromISO(row.updatedAt, DATE_FORMATS.TIME),
                      user:
                          row.updatedBy.firstName && row.updatedBy.lastName
                              ? `${row.updatedBy.firstName.charAt(0)}.${row.updatedBy.lastName}`
                              : "System"
                  })
                : "";
        const surgeonNamesDiscipline = row.practitionerIds.map((id) => allNamesObject[id] || t("App.unknown")).sort(sortWithLocale);

        const roomNames = row.locations
            .map((loc) => {
                const room = roomInfos.find((r) => r.id === loc);
                return room ? room.name : loc;
            })
            .sort(sortWithLocale);
        const dataFormatted = {
            name: t([`HealthcareService.${row.id}`, row.name]),
            surgeons: surgeonNamesDiscipline.length ? surgeonNamesDiscipline.join(", ") : "",
            rooms: roomNames.join(", "),
            updatedAt: lastUpdate
        };
        return {
            ...row,
            key: row.id,
            name: t([`HealthcareService.${row.id}`, row.name]),
            display: dataFormatted,
            tooltip: dataFormatted
        };
    });
    const labels = [
        {id: "name", label: t("AdminDisciplinesCanvas.discipline"), width: "10%", setTitle: true},
        {id: "surgeons", label: t("AdminDisciplinesCanvas.surgeons"), width: "30%", setTitle: true},
        {id: "rooms", label: t("AdminDisciplinesCanvas.rooms"), width: "30%", setTitle: true},
        {id: "updatedAt", label: t("AdminDisciplinesCanvas.lastUpdate"), width: "20%", setTitle: true}
    ];
    return (
        <DataTable
            data={disciplinesFormatted}
            disableSortColumns={["surgeons", "rooms"]}
            hasStickyPagination
            labels={labels}
            page={page}
            tableLeftRightPadding={16 * 10}
            tableTopBottomPadding={tableTopBottomPadding}
            onSetPage={onSetPage}
        />
    );
};

DisciplinesTable.propTypes = {
    disciplines: PropTypes.array.isRequired,
    tableTopBottomPadding: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onSetPage: PropTypes.func.isRequired
};

export default DisciplinesTable;
