import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {Backdrop} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {isPending, isResolved} from "../../redux/utils/status";
import {DisciplineSelect, loadDisciplineOptionsAction, selectOptions, selectStatus, SurgeonsMultiSelect} from "../disciplines";
import {loadEmployeesAction} from "../employees/employees_actions";
import {loadRoomsAction} from "../rooms/rooms_actions";
import {selectRoomInfos} from "../rooms/rooms_selectors";
import CommonSelector from "../shared/common_selector/common_selector";
import Loading from "../shared/loading/loading";
import useStyles from "./admin_disciplines_canvas.styles";
import DisciplinesTable from "./components/disciplines_table";

const AdminDisciplinesCanvas = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const disciplines = useSelector(selectOptions);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const loadStatus = useSelector(selectStatus);
    const roomInfos = useSelector(selectRoomInfos);

    // state for filters
    const [selectedDisciplines, setSelectedDisciplines] = useState([]);
    const [surgeons, setSurgeons] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [status, setStatus] = useState(""); // "active" or "deactivated"
    const [withOrWithoutSurgeons, setWithOrWithoutSurgeons] = useState("");
    const [withOrWithoutRooms, setWithOrWithoutRooms] = useState("");

    const [showAllOptions, setShowAllOptions] = useState(false);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (organizationId) {
            dispatch(loadRoomsAction(organizationId));
            dispatch(loadDisciplineOptionsAction(organizationId, true));
            dispatch(loadEmployeesAction(organizationId));
        }
    }, [organizationId]);

    const handleChangeSelectedDisciplines = (e) => {
        setSelectedDisciplines(e.target.value);
        setPage(0);
    };
    const handleChangeSurgeons = (items) => {
        setSurgeons(items);
        setPage(0);
    };
    const handleChangeRooms = (e) => {
        setRooms(e.target.value);
        setPage(0);
    };
    const handleResetRooms = () => {
        setRooms([]);
        setPage(0);
    };
    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
        setPage(0);
    };

    const handleResetStatus = () => {
        setStatus("");
        setPage(0);
    };

    const handleChangeWithOrWithoutSurgeons = (e) => {
        setWithOrWithoutSurgeons(e.target.value);
        setPage(0);
    };
    const handleResetWithOrWithoutSurgeons = () => {
        setWithOrWithoutSurgeons("");
        setPage(0);
    };

    const handleChangeWithOrWithoutRooms = (e) => {
        setWithOrWithoutRooms(e.target.value);
        setPage(0);
    };

    const handleResetWithOrWithoutRooms = () => {
        setWithOrWithoutRooms("");
        setPage(0);
    };

    const handleToggleOptions = () => {
        setShowAllOptions(!showAllOptions);
    };

    const handleReset = () => {
        setSelectedDisciplines([]);
        setSurgeons([]);
        setRooms([]);
        setStatus("");
        setWithOrWithoutRooms("");
        setWithOrWithoutSurgeons("");
    };
    const disciplineSelect = (
        <DisciplineSelect
            multiple
            onlyActive
            styles={{input: classes.input}}
            values={selectedDisciplines}
            onSelect={handleChangeSelectedDisciplines}
        />
    );
    const surgeonsSelect = (
        <div className={classes.ml1}>
            <SurgeonsMultiSelect styles={{input: classes.input}} values={surgeons} onSelect={handleChangeSurgeons} />
        </div>
    );
    const roomsList = roomInfos.map((room) => ({label: room.name, value: room.id}));
    const roomSelect = (
        <div className={classes.ml1}>
            <CommonSelector
                disabled={isPending(loadStatus)}
                items={roomsList}
                multiple
                placeholder={t("App.roomPlaceholder")}
                styles={{input: classes.input}}
                title={t("App.roomLabel")}
                value={rooms}
                onChange={handleChangeRooms}
                onReset={handleResetRooms}
            />
        </div>
    );
    const statusList = [
        {label: t("App.active"), value: "active"},
        {label: t("App.deactivated"), value: "deactivated"}
    ];
    const statusSelect = (
        <div className={classes.ml1}>
            <CommonSelector
                disabled={isPending(loadStatus)}
                items={statusList}
                placeholder={t("App.pleaseSelect")}
                styles={{input: classes.input}}
                title={t("AdminUserCanvas.status")}
                value={status}
                onChange={handleChangeStatus}
                onReset={handleResetStatus}
            />
        </div>
    );
    const withOrWithoutSurgeonsList = [
        {label: t("AdminDisciplinesCanvas.withSurgeons"), value: "with"},
        {label: t("AdminDisciplinesCanvas.withoutSurgeons"), value: "without"}
    ];
    const withOrWithoutSurgeonsSelect = (
        <CommonSelector
            disabled={isPending(loadStatus)}
            items={withOrWithoutSurgeonsList}
            placeholder={t("App.pleaseSelect")}
            styles={{input: classes.input}}
            title={t("AdminDisciplinesCanvas.withOrWithoutSurgeons")}
            value={withOrWithoutSurgeons}
            onChange={handleChangeWithOrWithoutSurgeons}
            onReset={handleResetWithOrWithoutSurgeons}
        />
    );
    const withOrWithoutRoomsList = [
        {label: t("AdminDisciplinesCanvas.withRooms"), value: "with"},
        {label: t("AdminDisciplinesCanvas.withoutRooms"), value: "without"}
    ];
    const withOrWithoutRoomsSelect = (
        <div className={classes.ml1}>
            <CommonSelector
                disabled={isPending(loadStatus)}
                items={withOrWithoutRoomsList}
                placeholder={t("App.pleaseSelect")}
                styles={{input: classes.input}}
                title={t("AdminDisciplinesCanvas.withOrWithourRooms")}
                value={withOrWithoutRooms}
                onChange={handleChangeWithOrWithoutRooms}
                onReset={handleResetWithOrWithoutRooms}
            />
        </div>
    );

    const toggleOptions = (
        <div
            className={classes.clickText}
            role={"button"}
            tabIndex={0}
            onClick={handleToggleOptions}
            onKeyDown={(e) => e.key === "Enter" && handleToggleOptions()}
        >
            {!showAllOptions && (
                <Fragment>
                    <div>{t("App.showAllOptions")}</div>
                    <KeyboardArrowDown className={classes.optionIcon} color="primary" />
                </Fragment>
            )}
            {showAllOptions && (
                <Fragment>
                    <div>{t("App.hideSomeOptions")}</div>
                    <KeyboardArrowUp className={classes.optionIcon} color="primary" />
                </Fragment>
            )}
        </div>
    );
    const noResult = (
        <div className={classes.ml1}>
            <div className={classes.info}>{t("AdminUserCanvas.noResult")}</div>
            <div
                className={classes.clickText}
                role={"button"}
                tabIndex={0}
                onClick={handleReset}
                onKeyDown={(e) => e.key === "Enter" && handleReset()}
            >
                {t("AdminUserCanvas.reset")}
            </div>
        </div>
    );

    // Set table padding: header(128) + page padding(100)
    let tableTopBottomPadding = 100 + 128 + 56; // in pixel
    if (showAllOptions) {
        tableTopBottomPadding += 50;
    }
    // Filter disciplines
    const filteredDisciplines = disciplines.filter((discipline) => {
        const containsDisciplines = selectedDisciplines.length ? selectedDisciplines.includes(discipline.id) : true;
        const containsSurgeons = surgeons.length ? discipline.practitionerIds.some((item) => surgeons.includes(item)) : true;
        const containsRooms = rooms.length ? rooms.some((room) => discipline.locations.includes(room)) : true;
        const statusBoolean = status === "active" ? true : false;
        const matchStatus = status ? discipline.isDeactivated === !statusBoolean : true;
        const withOrWithoutSurgeonsBoolean = withOrWithoutSurgeons === "with" ? true : false;
        const matchWithOrWithoutSurgeons = withOrWithoutSurgeons
            ? Boolean(discipline.practitionerIds.length) === withOrWithoutSurgeonsBoolean
            : true;

        const withOrWithoutRoomsBoolean = withOrWithoutRooms === "with" ? true : false;
        const matchWithOrWithoutRooms = withOrWithoutRooms ? Boolean(discipline.locations.length) === withOrWithoutRoomsBoolean : true;

        return (
            containsDisciplines && containsSurgeons && containsRooms && matchStatus && matchWithOrWithoutSurgeons && matchWithOrWithoutRooms
        );
    });
    return (
        <div className={classes.canvasRoot}>
            {isPending(loadStatus) && (
                <Backdrop className={classes.loading} open>
                    <Loading />
                </Backdrop>
            )}
            <div className={classes.filterWrapper}>
                <div className={classes.filterRow}>
                    {disciplineSelect}
                    {surgeonsSelect}
                    {roomSelect}
                    {statusSelect}
                    {toggleOptions}
                </div>
                {showAllOptions && (
                    <div className={classes.filterRow}>
                        {withOrWithoutSurgeonsSelect}
                        {withOrWithoutRoomsSelect}
                    </div>
                )}
            </div>
            {isResolved(loadStatus) && !filteredDisciplines.length && noResult}
            <div className={classes.tableWrapper}>
                <DisciplinesTable
                    disciplines={filteredDisciplines}
                    page={page}
                    tableTopBottomPadding={tableTopBottomPadding}
                    onSetPage={setPage}
                />
            </div>
        </div>
    );
};

export default AdminDisciplinesCanvas;
