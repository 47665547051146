import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        position: "absolute",
        top: 0,
        height: theme.spacing("l"),
        display: "flex",
        justifyContent: "center",
        width: `calc(100% + ${theme.custom.menuWidth})`,
        left: "-4rem",
        zIndex: theme.zIndex.appBar + 5
    },
    rootMenuOpen: {
        left: "-9.25rem",
        width: "calc(100% + 9.25rem)"
    },
    actions: {
        backgroundColor: theme.palette.primary.light,
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        boxShadow: theme.shadows[1],
        margin: "0 0.25rem"
    },
    actionsRightWrapper: {
        position: "absolute",
        right: "3rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        fontSize: "0.875em",
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        minWidth: "2rem"
    },
    danger: {
        color: `${theme.palette.error.main} !important`
    },
    iconButton: {
        width: theme.spacing("l"),
        height: theme.spacing("l"),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px"
    },
    icon: {
        color: theme.palette.primary.main,
        position: "relative",
        top: "-0.125rem",
        left: "0.125rem",
        width: "1.25rem",
        height: "1.25rem"
    },
    disabled: {
        opacity: 0.4
    },
    rightIconLabel: {
        [theme.breakpoints.down("lg")]: {
            display: "none"
        }
    },
    rightButton: {
        [theme.breakpoints.down("lg")]: {
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    centerIconLabel: {
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    centerButton: {
        [theme.breakpoints.down("md")]: {
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    number: {
        width: "1.125rem",
        height: "1.125rem",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
        position: "relative",
        top: "-0.125rem",
        left: theme.spacing("xs")
    },
    isOpened: {
        "backgroundColor": theme.palette.grey[100],
        "boxShadow": theme.shadows[1],
        "borderBottomRightRadius": "10px",
        "borderBottomLeftRadius": "10px",
        "&:hover": {
            backgroundColor: theme.palette.grey[100]
        },
        "borderTopLeftRadius": 0,
        "borderTopRightRadius": 0
    }
}));
