import {makeStyles} from "tss-react/mui";

/** @type Object */
export default makeStyles()((theme) => ({
    root: {
        width: "100%"
    },
    flex: {
        display: "flex"
    },
    title: {
        height: "6rem",
        width: "100%",
        padding: "35px 30px",
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "23px",
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    content: {
        padding: "30px"
    },
    contentTabs: {
        margin: "30px 30px 10px 30px",
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    menu: {
        marginBottom: "1rem"
    },
    row: {
        display: "flex",
        width: "100%",
        marginBottom: "2rem"
    },
    divider: {
        marginBottom: "40px",
        marginTop: "40px"
    },
    tab: {
        textTransform: "none",
        fontSize: "14px"
    },
    languageTab: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    languageIcon: {
        marginRight: "8px",
        fontSize: "24px"
    },
    tag: {
        position: "absolute",
        top: "-0.75rem",
        height: "1.875rem",
        lineHeight: "1.875rem",
        borderRadius: "1.875rem",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightBold,
        padding: "0 1rem",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center"
    },
    passwordRoot: {
        display: "flex",
        flexWrap: "wrap",
        height: "15rem",
        padding: "1rem"
    },
    subTitle: {
        marginBottom: "1rem",
        color: theme.palette.primary.main
    },
    input: {
        height: "1.5rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        padding: `${theme.spacing("xxs")} 0`
    },
    inputWrapper: {
        margin: "0 20px 20px 0",
        width: "400px"
    },
    inputPassword: {
        minWidth: "330px",
        height: "1.5rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        padding: `${theme.spacing("xxs")} 0`
    },
    inputSmall: {
        width: "8rem",
        height: "1.5rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        padding: `${theme.spacing("xxs")} 0`
    },
    avatarWrapper: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        // !Important is necessary because the styles are not being applied in the right order.
        // .MuiAvatar-root has preference over this class, even though we are importing useStyles
        // after the Avatar component in profile_username.js
        width: "48px !important",
        height: "48px !important",
        fontSize: "20px !important",
        marginBottom: "0 !important"
    },
    select: {
        width: "15rem"
    },
    marginRight: {
        marginRight: theme.spacing("m")
    },
    button: {
        height: "38px",
        width: "108px",
        minWidth: "108px"
    },
    buttonReset: {
        "fontSize": "0.7rem",
        "textDecoration": "underline",
        "color": theme.palette.primary.main,
        "textTransform": "capitalize",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline"
        }
    },
    buttonCancel: {
        marginRight: "20px"
    },
    iconButtonSize: {
        width: 20,
        height: 20,
        padding: 0
    },
    rulesWrapper: {
        height: "44px",
        marginLeft: "30px",
        display: "flex",
        alignItems: "flex-end"
    },
    icon: {
        width: "24px",
        height: "24px",
        marginLeft: theme.spacing("xs"),
        position: "relative",
        top: "2px"
    },
    buttonsWrapper: {
        display: "flex"
    },
    sectionWrapper: {
        display: "flex"
    },
    // userDataWrapper: {
    //     display: "flex"
    // },
    borderBottom: {
        color: `${theme.palette.grey[100]}`,
        width: "100%"
    },
    sectionSubtitle: {
        marginBottom: "12px"
    },
    profilePhotoWrapper: {
        marginLeft: "50px"
    },
    pictureSubtitle: {
        marginBottom: "10px",
        whiteSpace: "nowrap"
    },
    editEmail: {
        color: theme.palette.primary.main,
        marginLeft: "8px",
        whiteSpace: "nowrap"
    },
    emailWrapper: {display: "grid", gridTemplateColumns: "400px 1fr"},
    oldPasswordWrapper: {display: "grid", gridTemplateColumns: "400px 1fr"}
}));
