import {Create} from "@mui/icons-material";
import {DialogContent, DialogTitle} from "@mui/material";
import {arrayOf, bool, func, shape, string} from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {saveUserAction} from "../../pages/admin_user/admin_user_actions";
import {selectRoles} from "../../pages/admin_user/admin_user_selectors";
import {selectCurrentUser} from "../../redux/app_selectors";
import {sortArrayOfObjectsByKey} from "../../utils/sort_array_of_objects_by_key";
import {selectEmployeeIds} from "../employees/employees_selectors";
import {selectFeSettings} from "../fe_settings/fe_settings_selectors";
import {selectStandardNamesArray} from "../private_data/private_data_selectors";
import useStyles from "./admin_user_layer.styles";
import {AdminUserForm} from "./components/admin_user_form";

const initialUserState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    roles: ["viewer"],
    active: true,
    practitionerId: "",
    id: ""
};

export const AdminUserLayer = ({user, onClose}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // User state
    const [userState, setUserState] = useState(initialUserState);

    const isUserNew = user ? false : true;

    // Redux
    const rolesRedux = useSelector(selectRoles);
    const {organizationId} = useSelector(selectCurrentUser);
    const empIds = useSelector(selectEmployeeIds);
    const practitonerInitialNameList = useSelector(selectStandardNamesArray({ids: empIds, type: "practitioner"}));
    const {excludedRoles} = useSelector(selectFeSettings);

    useEffect(() => {
        if (!isUserNew) {
            for (const key in userState) {
                setUserState((prevUserState) => ({
                    ...prevUserState,
                    [key]: user[key]
                }));
            }
        }
    }, [user]);

    const handleResetRoles = () =>
        setUserState((prevUserState) => ({
            ...prevUserState,
            roles: []
        }));

    const rolesList = rolesRedux
        .filter((role) => !excludedRoles?.includes(role.name))
        .map((role) => ({
            label: t(`Roles.${role.name}`),
            value: role.name
        }))
        .filter((item) => item.value !== "next-admin")
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    const statusList = [
        {label: t("App.active"), value: true},
        {label: t("App.deactivated"), value: false}
    ];

    const empIdsList = empIds.map((id) => ({label: practitonerInitialNameList.find((prac) => prac.id === id)?.name, value: id}));

    /**
     * Handler to save the app user data
     * @param {UserFormData} data - The submitted form data
     * @param {object} valuesChanged - The changed form values
     * @returns {undefined}
     */

    const handleSave = (data, valuesChanged) => {
        const {email, firstName, lastName, active, practitionerId: dataPractitionerId} = data;
        const initials = firstName.charAt(0) + lastName.charAt(0);

        const type = isUserNew ? "add" : "modify";
        const status = active ? "active" : "deactivated";
        const practitionerId = dataPractitionerId || "";

        if (isUserNew) {
            dispatch(saveUserAction({...data, organizationId, email, initials, status, practitionerId}, type));
        } else {
            dispatch(saveUserAction({...valuesChanged, organizationId, id: data.id, status, practitionerId}, type));
        }
    };

    return (
        <Fragment>
            <DialogTitle className={classes.title}>
                <Create className={classes.icon} color="primary" />
                {t("AdminUserLayer.title")}
            </DialogTitle>
            <DialogContent>
                <AdminUserForm
                    defaultValues={userState}
                    empIdsList={empIdsList}
                    handleResetRoles={handleResetRoles}
                    handleSave={handleSave}
                    isUserNew={isUserNew}
                    rolesList={rolesList}
                    statusList={statusList}
                    onClose={onClose}
                    onReset={handleResetRoles}
                />
            </DialogContent>
        </Fragment>
    );
};

AdminUserLayer.propTypes = {
    user: shape({
        id: string.isRequired, // id for identity server database
        roles: arrayOf(string).isRequired,
        active: bool,
        email: string.isRequired,
        firstName: string.isRequired,
        lastName: string.isRequired,
        practitionerId: string,
        organizationId: string.isRequired,
        updatedAt: string,
        updatedBy: shape({
            email: string,
            firstName: string,
            lastName: string
        })
    }),
    onClose: func.isRequired
};

export default AdminUserLayer;
