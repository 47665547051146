/**
 * @fileoverview API for update users account info
 */

import axios from "../../middleware/axios.middelware";

/**
 * update password of the user
 *
 * @param {String} id
 * @param {String} old_password
 * @param {String} new_password
 * @return {Promise}
 */
function updatePassword(id, old_password, new_password) {
    const URL_USER = `${global.IDENTITY_SERVER_AUTHORITY}users`;
    const data = {
        oldPassword: old_password,
        newPassword: new_password
    };
    return axios.patch(`${URL_USER}/${id}`, data);
}

/**
 * update name of the user
 * @param {String} id
 * @param {String} firstName
 * @param {String} lastName
 * @return {Promise}
 */
function updateName(id, firstName, lastName) {
    const URL_USER = `${global.IDENTITY_SERVER_AUTHORITY}users`;
    const data = {
        firstName,
        lastName
    };
    return axios.patch(`${URL_USER}/${id}`, data);
}

export {updatePassword, updateName};
