// @ts-check
import {Button} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {selectStandardNamesArray} from "../../private_data/private_data_selectors";
import DataTable from "../../shared/data_table/data_table";
import PermissionTooltip from "../../shared/permission_tooltip/permission_tooltip";
import useStyles from "./user_table.styles";

/**
 * @typedef {Object} UpdatedBy
 * @property {String} [email]
 * @property {String} [firstName]
 * @property {String} [lastName]
 */
/**
 * @typedef {Object} Users
 * @property {String} id objectId
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} email
 * @property {String} organizationId
 * @property {String} practitionerId
 * @property {UpdatedBy} updatedBy
 * @property {String} [updatedAt]
 * @property {Array<String>} roles
 * @property {Boolean} active
 */
/**
 * UserTable component
 * @param {Object} props
 * @param {Array<Users>} props.users
 * @param {Number} props.tableTopBottomPadding
 * @param {Number} props.page=0
 * @param {Function} props.onSetPage
 * @param {Function} props.onEdit
 * @return {React.ReactElement}
 */
const UserTable = ({users, onEdit, tableTopBottomPadding, page = 0, onSetPage}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();

    const {formatFromJSDate} = useContext(DateContext);

    const practitionerNamesArray = useSelector(
        selectStandardNamesArray({ids: users.map((user) => user.practitionerId) || [], type: "practitioner"})
    );

    const {isGranted} = useSecurity();

    const usersFormatted = users.map((row) => {
        const date = formatFromJSDate(new Date(row.updatedAt), DATE_FORMATS.DATE);
        const time = formatFromJSDate(new Date(row.updatedAt), DATE_FORMATS.TIME);
        const lastUpdate =
            row.updatedBy && row.updatedAt
                ? t("AdminUserCanvas.lastUpdateText", {
                      date,
                      time,
                      user:
                          row.updatedBy.firstName && row.updatedBy.lastName
                              ? `${row.updatedBy.firstName.charAt(0)}.${row.updatedBy.lastName}`
                              : "System"
                  })
                : "";
        let isAllowed = isGranted(PERMISSION.MODIFY_USER);
        if (row.roles.includes("next-admin") && !isGranted(PERMISSION.MODIFY_USER_NEXTADMIN)) {
            isAllowed = false;
        }
        const practitionerName = practitionerNamesArray.find((el) => el.id === row.practitionerId);
        const dataFormatted = {
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            roles: row.roles.map((role) => t(`Roles.${role}`, role)).join(", "),
            practitionerId: row.practitionerId && row.practitionerId !== "none" && practitionerName ? practitionerName.name : "",
            updatedAt: lastUpdate
        };
        return {
            ...row,
            key: row.id,
            practitionerId: row.practitionerId !== "none" && practitionerName ? practitionerName.name : "", // for sort
            display: {
                ...dataFormatted,
                actions: (
                    <PermissionTooltip isAllowed={isAllowed}>
                        <Button
                            classes={{
                                text: cx({
                                    [classes.button]: isAllowed,
                                    [classes.disabled]: !isAllowed
                                })
                            }}
                            disabled={!isAllowed}
                            size="small"
                            onClick={() => onEdit(row)}
                        >
                            {t("App.edit")}
                        </Button>
                    </PermissionTooltip>
                )
            },
            tooltip: dataFormatted,
            isDeactivated: !row.active
        };
    });
    const labels = [
        {id: "firstName", label: t("AdminUserCanvas.firstName"), width: "10%", setTitle: true},
        {id: "lastName", label: t("AdminUserCanvas.lastName"), width: "10%", setTitle: true},
        {id: "email", label: t("AdminUserCanvas.email"), width: "20%", setTitle: true},
        {id: "roles", label: t("AdminUserCanvas.roles"), width: "20%", setTitle: true},
        {id: "practitionerId", label: t("AdminUserCanvas.practitionerId"), width: "10%", setTitle: true},
        {id: "updatedAt", label: t("AdminUserCanvas.lastUpdate"), width: "20%", setTitle: true},
        {id: "actions", label: t("AdminUserCanvas.actions"), width: "10%"}
    ];
    return (
        <DataTable
            data={usersFormatted.filter((user) => !user.roles.includes("next-admin"))}
            defaultSorted="lastName"
            disableSortColumns={["actions"]}
            hasStickyPagination
            labels={labels}
            page={page}
            tableLeftRightPadding={16 * 10 + 30}
            tableTopBottomPadding={tableTopBottomPadding}
            onSetPage={onSetPage}
        />
    );
};

UserTable.propTypes = {
    users: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    tableTopBottomPadding: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onSetPage: PropTypes.func.isRequired
};
UserTable.defaultProps = {
    page: 0
};
export default UserTable;
