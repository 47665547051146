// @ts-check
import {yupResolver} from "@hookform/resolvers/yup";
import {Button} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import {array, bool, func, object} from "prop-types";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import * as yup from "yup";

import {selectSaveError} from "../../../pages/admin_user/admin_user_selectors";
import {selectUserStrategy} from "../../../redux/app_selectors";
import {checkDirtyValues, keepTrueValues} from "../../../utils/form_utils";
import {AdornmentModified} from "../../shared/adornment_modified/adornment_modified";
import {
    ControlledAutocomplete,
    ControlledSelectorMultiple,
    ControlledSelectorSingle,
    ControlledTextField
} from "../../shared/controlled_form";
import PasswordRules from "../../shared/password_rules/password_rules";
import useStyles from "./admin_user_form.styles";

const userRoles = {
    medicalOpManager: "medical-op-manager"
};

const userNoPasswordSchema = yup.object().shape({
    firstName: yup.string().min(2, "AdminUserLayer.nameToShort"),
    lastName: yup.string().min(2, "AdminUserLayer.nameToShort"),
    email: yup.string().email("AdminUserLayer.emailInvalid"),
    roles: yup.array().min(1, "AdminUserLayer.rolesEmpty"), // message not used
    practitionerId: yup.string().when("roles", {
        is: (roles) => roles.includes(userRoles.medicalOpManager),
        then: () => yup.string().required("AdminUserLayer.practitionersEmpty"), // message not used
        otherwise: () => yup.string().nullable()
    })
});

const userWithPasswordSchema = yup.object().shape({
    firstName: yup.string().min(2, "AdminUserLayer.nameToShort"),
    lastName: yup.string().min(2, "AdminUserLayer.nameToShort"),
    email: yup.string().email("AdminUserLayer.emailInvalid"),
    password: yup
        .string()
        .min(12, "AdminUserLayer.passwordError")
        .matches(/^(?=.*\W).*$/i, "AdminUserLayer.passwordError")
        .matches(/^(?!.*([A-Za-z0-9])\1{2}).*$/, "AdminUserLayer.passwordError")
        .test("notInvalidWords", "AdminUserLayer.passwordError", (value) => !value.includes("nextor")),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref("password")], "AdminUserLayer.passwordUnequal")
        .required("AdminUserLayer.passwordError"),
    roles: yup.array().min(1, "AdminUserLayer.rolesEmpty"), // message not used
    practitionerId: yup.string().when("roles", {
        is: (roles) => roles.includes(userRoles.medicalOpManager),
        then: () => yup.string().required("AdminUserLayer.practitionersEmpty"), // message not used
        otherwise: () => yup.string().nullable()
    })
});

/**
 * @typedef DefaultValues
 * @type {object}
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} passwordConfirm
 * @property {Array<string>} roles
 * @property {boolean} active
 * @property {string|null} practitionerId
 */

/**
 *
 * @param {object} props
 * @param {DefaultValues} props.defaultValues
 * @param {boolean} props.isUserNew Wether the user already exists
 * @param {Array<{label: string, value: string}>} props.rolesList The list of roles
 * @param {Array<{label: string, value: string}>} props.statusList The list of possible statuses
 * @param {Array<{label: string, value: string}>} props.empIdsList The list of employees ids
 * @param {Function} props.handleSave A handler to save the form values
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClose A handler to close the "Edit User" layer
 * @return {React.ReactElement}
 */
export const AdminUserForm = ({defaultValues, isUserNew, rolesList, statusList, empIdsList, handleSave, onClose}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const {
        changeName: isChangeNameAllowed,
        changeRoles: isChangeRolesAllowed,
        changeActive: isChangeStatusAllowed,
        changePractitionerId: isChangePractitionerIdAllowed
    } = useSelector(selectUserStrategy);
    const saveError = useSelector(selectSaveError);

    // state
    const [showPassword, setShowPassword] = useState({password: false, passwordConfirm: false});
    const {passwordConfirm, password} = showPassword;

    // form
    const resolver = isUserNew ? userWithPasswordSchema : userNoPasswordSchema;
    const {
        control,
        reset,
        formState: {errors, isSubmitting, dirtyFields},
        handleSubmit,
        getValues,
        setError
    } = useForm({defaultValues, resolver: yupResolver(resolver)});
    const hasError = !isEmpty(errors);

    // useEffects
    useEffect(() => reset(defaultValues), [defaultValues]);

    useEffect(() => {
        saveError
            ?.filter(({param}) => Boolean(param))
            .map(({param}) => {
                if (param === "status") {
                    return "active";
                }
                if (param.includes("roles")) {
                    return "roles";
                }
                return param;
            })
            .forEach((param) => setError(param, {type: "invalid", message: "defaultError"}));
    }, [saveError]);

    // handlers
    const handleShowPassword = (_, key) =>
        setShowPassword({
            ...showPassword,
            [key]: !showPassword[key]
        });

    const endAdornment = (textToShow, isVisible) => (
        <AdornmentModified handler={(e) => handleShowPassword(e, textToShow)} hasError={hasError} isVisible={isVisible} />
    );

    /**
     * Submit the form data to the handler
     * @param {UserFormData} data
     */
    const onSubmit = (data) => {
        // Necessary because when we use the reset(), dirtyFields is an object that
        // contains ALL the form fields, and not just the dirty ones.
        // The object has the structure {key: boolean}, where key is the form field.
        const dirtyFieldsOnly = keepTrueValues(dirtyFields);
        const dataChanged = checkDirtyValues(dirtyFieldsOnly, data);
        handleSave(data, dataChanged);
    };

    return (
        <form autoComplete="off" className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.errorWrapper}>{hasError && t("App.defaultFormError")}</div>
            <div className={classes.formRow}>
                {/* Email */}
                <ControlledTextField
                    control={control}
                    disabled={!isUserNew}
                    inputProps={{autoComplete: "off"}}
                    label={t("AdminUserCanvas.email")}
                    name="email"
                    required
                />
            </div>

            <div className={classes.formRow}>
                {/* First name */}
                <ControlledTextField
                    control={control}
                    disabled={!isChangeNameAllowed}
                    inputProps={{autoComplete: "off"}}
                    label={t("AdminUserCanvas.firstName")}
                    name="firstName"
                    required
                />

                {/* Last Name */}
                <ControlledTextField
                    classesStyle={classes.ml1}
                    control={control}
                    disabled={!isChangeNameAllowed}
                    inputProps={{autoComplete: "off"}}
                    label={t("AdminUserCanvas.lastName")}
                    name="lastName"
                    required
                />
            </div>
            {isUserNew && (
                <div className={classes.formRow}>
                    {/* Password */}
                    <ControlledTextField
                        control={control}
                        inputProps={{form: {autocomplete: "off"}}}
                        InputProps={{endAdornment: endAdornment("password", password)}}
                        label={t("AdminUserLayer.password")}
                        name="password"
                        placeholder="************"
                        required
                        type={password ? "text" : "password"}
                    />

                    {/* Password confirm */}
                    <ControlledTextField
                        classesStyle={classes.ml1}
                        control={control}
                        inputProps={{form: {autocomplete: "off"}}}
                        InputProps={{endAdornment: endAdornment("passwordConfirm", passwordConfirm)}}
                        label={t("AdminUserLayer.passwordConfirm")}
                        name="passwordConfirm"
                        placeholder="************"
                        required
                        type={passwordConfirm ? "text" : "password"}
                    />
                </div>
            )}
            <div className={classes.formRow}>
                {/* Roles select */}
                <ControlledSelectorMultiple
                    classesStyle={classes.select}
                    control={control}
                    disabled={!isChangeRolesAllowed}
                    errors={errors.roles}
                    getValues={getValues}
                    hasOwnErrorMessage={false}
                    items={rolesList}
                    name="roles"
                    reset={reset}
                    title={t("AdminUserCanvas.roles")}
                />

                {/* Status select */}
                <ControlledSelectorSingle
                    classesStyle={classes.select}
                    control={control}
                    disabled={!isChangeStatusAllowed}
                    errors={errors.active}
                    items={statusList}
                    name="active"
                    title={t("AdminUserCanvas.status")}
                />

                {/* Practitioner Id select */}
                <ControlledAutocomplete
                    allowFreeText={false}
                    classesStyle={classes.autocomplete}
                    control={control}
                    disabled={!isChangePractitionerIdAllowed}
                    errors={errors.practitionerId}
                    items={empIdsList}
                    name="practitionerId"
                    title={t("AdminUserCanvas.practitionerId")}
                />
            </div>
            <div className={classes.buttonsWrapper}>
                <Button className={classes.button} color="primary" disabled={isSubmitting} type="submit" variant="contained">
                    {t("App.confirm")}
                </Button>
                <Button
                    className={cx(classes.button, classes.buttonWhite, classes.ml1)}
                    color="primary"
                    variant="outlined"
                    onClick={onClose}
                >
                    {t("App.cancel")}
                </Button>
                {isUserNew && (
                    <div className={classes.rulesWrapper}>
                        <PasswordRules />
                    </div>
                )}
            </div>
        </form>
    );
};
AdminUserForm.propTypes = {
    defaultValues: object.isRequired,
    isUserNew: bool.isRequired,
    rolesList: array.isRequired,
    statusList: array.isRequired,
    empIdsList: array.isRequired,
    handleSave: func.isRequired,
    onClose: func.isRequired
};
