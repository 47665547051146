import {Box, Button, IconButton} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectShowFullMainMenu} from "../../redux/app_selectors";
import useStyles from "./action_items.styles";
import iconMapping from "./icon_mapping_actions";

/**
 * show actions items
 * @param {array} actionsCenter
 * @param {array} actionsRight
 * @param {array} actionsIcon
 * @return {JSX}
 */
export const ActionItems = ({actionsCenter, actionsRight, actionsIcon}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();

    // Redux
    const showFullMainMenu = useSelector(selectShowFullMainMenu);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    const actionsCenterFormatted = actionsCenter
        ? actionsCenter.map((action) => {
              const IconName = iconMapping[action.iconName] || iconMapping.Default;
              const number = action.number ? (action.number > 9 ? "9+" : action.number) : null;
              return (
                  <div className={classes.actions} data-testid={`actionItem-${action.translationKey}`} key={t(action.translationKey)}>
                      <Button
                          className={cx(classes.button, classes.centerButton, {
                              [classes.disabled]: action.disabled,
                              [classes.isOpened]: action.isOpened,
                              [classes.danger]: action.color === "danger"
                          })}
                          startIcon={
                              <IconName
                                  className={cx(classes.icon, {
                                      [classes.danger]: action.color === "danger"
                                  })}
                              />
                          }
                          onClick={action.handler}
                          onKeyPress={handleKeyPress}
                      >
                          <div className={classes.centerIconLabel}>{t(action.translationKey)}</div>
                          {number && <div className={classes.number}>{number}</div>}
                      </Button>
                  </div>
              );
          })
        : [];
    const actionsIconFormatted = actionsIcon
        ? actionsIcon.map((action) => {
              const IconName = iconMapping[action.iconName] || iconMapping.Default;
              return (
                  <div className={classes.actions} key={t(action.translationKey)}>
                      <IconButton
                          className={cx(classes.button, classes.iconButton, {
                              [classes.isOpened]: action.isOpened,
                              [classes.danger]: action.color === "danger"
                          })}
                          size="small"
                          title={t(action.translationKey)}
                          onClick={action.handler}
                          onKeyPress={handleKeyPress}
                      >
                          <IconName
                              className={cx(classes.icon, {
                                  [classes.danger]: action.color === "danger"
                              })}
                          />
                      </IconButton>
                  </div>
              );
          })
        : [];

    const actionsRightFormatted = actionsRight
        ? actionsRight.map((action, index) => {
              const IconName = iconMapping[action.iconName] || iconMapping.Default;
              const number = action.number ? (action.number > 9 ? "9+" : action.number) : null;
              return (
                  <Box className={classes.actions} key={t(action.translationKey)} ml={index === 0 ? 1.5 : 0.5}>
                      <Button
                          className={cx(classes.button, classes.rightButton, {
                              [classes.isOpened]: action.isOpened,
                              [classes.danger]: action.color === "danger"
                          })}
                          startIcon={
                              <IconName
                                  className={cx(classes.icon, {
                                      [classes.danger]: action.color === "danger"
                                  })}
                              />
                          }
                          onClick={action.handler}
                          onKeyPress={handleKeyPress}
                      >
                          <Box className={classes.rightIconLabel}>{t(action.translationKey)}</Box>
                          {number && <Box className={classes.number}>{number}</Box>}
                      </Button>
                  </Box>
              );
          })
        : [];

    return (
        <Box
            className={cx(classes.root, {
                [classes.rootMenuOpen]: showFullMainMenu
            })}
        >
            {actionsCenterFormatted}
            <Box className={classes.actionsRightWrapper}>
                {actionsIconFormatted}
                {actionsRightFormatted}
            </Box>
        </Box>
    );
};
const actionsPropTypes = PropTypes.shape({
    handler: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    iconName: PropTypes.string.isRequired,
    translationKey: PropTypes.string.isRequired,
    number: PropTypes.number,
    isOpened: PropTypes.bool
});
ActionItems.propTypes = {
    actionsCenter: PropTypes.arrayOf(actionsPropTypes),
    actionsRight: PropTypes.arrayOf(actionsPropTypes),
    actionsIcon: PropTypes.arrayOf(actionsPropTypes)
};
export default ActionItems;
