import {Button, Divider, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {setLanguageAction} from "../../../redux/actions";
import {selectLanguage} from "../../../redux/app_selectors";
import {ControlledSelectorSingle} from "../../shared/controlled_form";
import useStyles from "../account.styles";

export const ProfileLanguage = () => {
    const {classes, cx} = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    // redux
    const lang = useSelector(selectLanguage);

    // form
    const defaultValues = {
        language: lang ?? ""
    };
    const {
        control,
        handleSubmit,
        reset,
        formState: {isSubmitting}
    } = useForm({defaultValues});

    // useEffects
    useEffect(() => reset(defaultValues), [lang]);

    const languageList = [
        {value: "de", label: t("Account.de")},
        {value: "en-US", label: t("Account.en-US")}
    ];

    const onSubmit = ({language}) => {
        dispatch(setLanguageAction(language));
        i18n.changeLanguage(language);
    };

    return (
        <div className={classes.content}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography className={classes.sectionSubtitle} variant="h6">
                    {t("Account.changeLanguage")}
                </Typography>

                <div data-testid="language-selector">
                    <ControlledSelectorSingle
                        control={control}
                        items={languageList}
                        name="language"
                        styles={classes.select}
                        title={t("Account.language")}
                    />
                </div>
                <Divider className={classes.divider} />
                <div className={classes.buttonsWrapper}>
                    <Button
                        className={cx(classes.button, classes.buttonCancel)}
                        color="primary"
                        disabled={isSubmitting}
                        size="small"
                        variant="outlined"
                        onClick={() => reset()}
                    >
                        {t("App.cancel")}
                    </Button>
                    <Button
                        className={classes.button}
                        color="primary"
                        data-testid="language-button"
                        disabled={isSubmitting}
                        size="small"
                        type="submit"
                        variant="contained"
                    >
                        {t("Account.save")}
                    </Button>
                </div>
            </form>
        </div>
    );
};
