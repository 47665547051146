import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    canvasRoot: {
        height: "100%",
        width: "100%"
    },
    input: {
        width: "10rem"
    },
    table: {
        marginTop: "6rem"
    },
    ml1: {
        marginLeft: theme.spacing("m"),
        display: "flex"
    },
    filterWrapper: {
        "padding": `1.6rem ${theme.spacing("m")} ${theme.spacing("s")} ${theme.spacing("m")}`,
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "0.688rem"
        }
    },
    filterRow: {
        display: "flex",
        marginBottom: theme.spacing("s")
    },
    loading: {
        zIndex: theme.zIndex.drawer + 1
    },
    clickText: {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: theme.typography.fontWeightMedium,
        marginLeft: theme.spacing("s"),
        cursor: "pointer",
        display: "flex",
        alignItems: "flex-end"
    },
    optionIcon: {
        fontSize: "1rem",
        position: "relative"
    },
    info: {
        fontSize: "0.75rem",
        color: theme.palette.grey[700],
        marginLeft: theme.spacing("xs")
    },
    tableWrapper: {
        padding: `0 ${theme.spacing("m")}`
    }
}));
