// eslint-disable-next-line simple-import-sort/imports
import {Tab, Tabs} from "@mui/material";
import {Language} from "@mui/icons-material";
import {node, number} from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Message from "../../components/shared/message/message";
import usePrevious from "../../hooks/usePrevious";
import {isPending, isRejected, isResolved} from "../../redux/utils/status";
import {selectStatus} from "./account_selector";
import {ProfileUserName} from "./components/profile_username";
import {ProfileLanguage} from "./components/profile_language";
// The order of import affects how Material-UI apply styles, so we cannot order the imports here
import useStyles from "./account.styles";
import {ProfilePassword} from "./components/profile_password";
import {selectUserStrategy} from "../../redux/app_selectors";
/**
 *
 * @param {object} props
 * @param {React.ReactChildren} props.children
 * @param {number} props.value
 * @param {number} props.index
 * @return {React.ReactNode}
 */

const TabPanel = ({children, value, index}) => {
    return (
        <div hidden={value !== index} id={`simple-tabpanel-${index}`} role="tabpanel">
            {value === index && <div>{children}</div>}
        </div>
    );
};
const a11yProps = (index) => ({
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    "value": index
});

TabPanel.propTypes = {
    children: node.isRequired,
    value: number.isRequired,
    index: number.isRequired
};

const Account = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const status = useSelector(selectStatus);
    const [message, setMessage] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const previousStatus = usePrevious(status);
    const {changePassword: isChangePasswordAllowed} = useSelector(selectUserStrategy);

    useEffect(() => {
        if (isPending(previousStatus) && isResolved(status)) {
            setMessage({severity: "success", text: t("Account.saved")});
        }
    }, [previousStatus, status]);

    useEffect(() => {
        if (isPending(previousStatus) && isRejected(status)) {
            setMessage({severity: "error", text: t("Account.error")});
        }
    }, [previousStatus, status]);

    const handleChange = (_, newTab) => setTabValue(newTab);

    const languageTab = (
        <div className={classes.languageTab}>
            <Language className={classes.languageIcon} />
            {t("Account.language")}
        </div>
    );

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.title}>{t("Account.user")}</div>
            </div>
            <div className={classes.contentTabs}>
                <Tabs indicatorColor="primary" value={tabValue} onChange={handleChange}>
                    <Tab className={classes.tab} label={t("Account.userName")} {...a11yProps(0)} />
                    {isChangePasswordAllowed && (
                        <Tab label={t("Account.password")} {...a11yProps(1)} className={classes.tab} data-testid="password-tab" />
                    )}
                    <Tab className={classes.tab} data-testid="language-tab" label={languageTab} {...a11yProps(2)} />
                </Tabs>
            </div>
            <TabPanel index={0} value={tabValue}>
                <ProfileUserName />
            </TabPanel>
            {isChangePasswordAllowed && (
                <TabPanel index={1} value={tabValue}>
                    <ProfilePassword />
                </TabPanel>
            )}
            <TabPanel index={2} value={tabValue}>
                <div>
                    <ProfileLanguage />
                </div>
            </TabPanel>
            {message && <Message message={message.text} severity={message.severity} onClose={() => setMessage("")} />}
        </div>
    );
};

export default Account;
