import {
    AddBox,
    Apartment,
    AssignmentTurnedIn,
    CropFree,
    FormatListBulleted,
    List as ListIcon,
    LocalHospital,
    PieChart,
    Redo,
    Search,
    SettingsEthernet,
    TrackChanges,
    Undo,
    ViewModule
} from "@mui/icons-material";

import AbsenceIcon from "../shared/icons/absence_icon";
import AvailableIcon from "../shared/icons/available_icon";

export default {
    Apartment,
    CropFree,
    Undo,
    LocalHospital,
    AssignmentTurnedIn,
    Redo,
    ViewModule,
    SettingsEthernet,
    FormatListBulleted,
    PieChart,
    TrackChanges,
    Search,
    AddBox,
    AvailableIcon,
    AbsenceIcon,
    Default: ListIcon
};
