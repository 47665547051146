import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    form: {
        "display": "flex",
        "flexDirection": "column",
        "width": "30rem",
        "overflowX": "hidden",
        "& .MuiIconButton-root:hover": {
            backgroundColor: "transparent !important"
        }
    },
    formRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing("s")
    },
    button: {
        fontSize: "0.875rem",
        padding: `${theme.spacing("xs")} 1rem`,
        letterSpacing: "0.3px"
    },
    buttonsWrapper: {
        marginBottom: theme.spacing("l"),
        marginTop: theme.spacing("m"),
        display: "flex",
        alignItems: "center",
        flexDirection: "row"
    },
    buttonWhite: {
        backgroundColor: theme.palette.primary.contrastText
    },
    name: {
        width: "14.5rem"
    },
    ml1: {
        marginLeft: theme.spacing("m")
    },
    fullWidth: {
        width: "100%"
    },
    select: {width: "8rem"},
    autocomplete: {width: "152px", height: "50px"},
    rulesWrapper: {
        marginLeft: "30px",
        display: "flex",
        alignItems: "flex-end"
    },
    errorWrapper: {
        height: "25px",
        color: theme.palette.error.main,
        fontSize: "14px",
        marginBottom: theme.spacing("s")
    }
}));
