import {yupResolver} from "@hookform/resolvers/yup";
import {InfoOutlined} from "@mui/icons-material";
import {Avatar, Button, Divider, Tooltip, Typography} from "@mui/material";
import React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";

import {saveUserObject} from "../../../redux/actions";
import {selectCurrentUser, selectUserStrategy} from "../../../redux/app_selectors";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {ControlledTextField} from "../../shared/controlled_form";
import PermissionTooltip from "../../shared/permission_tooltip";
import useStyles from "../account.styles";
import {updateNameAction} from "../account_actions";

const inputProps = (className, dataTestId) => ({className, "data-testid": dataTestId});

const userSchema = yup.object().shape({
    firstName: yup.string().min(2, "Account.name_to_short"),
    lastName: yup.string().min(2, "Account.name_to_short")
});

export const ProfileUserName = () => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // redux
    const user = useSelector(selectCurrentUser);
    const {isGranted} = useSecurity();
    const isModifyAllowed = isGranted(PERMISSION.MODIFY_USER_OWN);
    const {changeName: isChangeNameAllowed} = useSelector(selectUserStrategy);
    const {id} = useSelector(selectCurrentUser);

    // form
    const defaultValues = {
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        email: user?.email ?? ""
    };

    const {control, formState, handleSubmit, reset} = useForm({
        resolver: yupResolver(userSchema),
        defaultValues
    });
    const {isSubmitting} = formState;

    const onSubmit = ({firstName, lastName}) => {
        dispatch(updateNameAction(id, firstName, lastName));
        dispatch(
            saveUserObject({
                ...user,
                firstName,
                lastName,
                id
            })
        );
    };

    return (
        <div className={classes.content}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.flex}>
                    <div>
                        <Typography className={classes.sectionSubtitle} variant="h6">
                            {t("Account.changeName")}
                        </Typography>

                        {/* First name */}
                        <div className={classes.inputWrapper}>
                            <ControlledTextField
                                control={control}
                                disabled={!isChangeNameAllowed}
                                inputProps={inputProps(classes.input, "user-first-name")}
                                label={t("Account.name")}
                                name="firstName"
                            />
                        </div>

                        {/* Last name */}
                        <div className={classes.inputWrapper}>
                            <ControlledTextField
                                control={control}
                                disabled={!isChangeNameAllowed}
                                inputProps={inputProps(classes.input, "user-last-name")}
                                label={t("Account.lastName")}
                                name="lastName"
                            />
                        </div>

                        {/* Email */}
                        <div className={cx(classes.inputWrapper, classes.emailWrapper)}>
                            <ControlledTextField
                                control={control}
                                disabled
                                inputProps={inputProps(classes.input, "user-email")}
                                label={t("Account.email")}
                                name="email"
                            />
                            <div className={classes.rulesWrapper}>
                                <Tooltip title={t("App.noRightsEmail")}>
                                    <InfoOutlined className={classes.icon} />
                                </Tooltip>
                                <span className={classes.editEmail}>{t("Account.cannotEditEmail")}</span>
                            </div>
                        </div>
                    </div>

                    {/* Profile Picture */}
                    <div className={classes.profilePhotoWrapper}>
                        <Typography className={classes.pictureSubtitle} variant="h6">
                            {t("Account.changePhoto")}
                        </Typography>
                        <div className={classes.avatarWrapper}>
                            <Avatar classes={{root: classes.avatar}} data-testid="info-avatar">
                                {user.initials}
                            </Avatar>
                            <>
                                <input accept="image/*" style={{display: "none"}} type="file" />
                                <label htmlFor="icon-button-file">
                                    <Button className={classes.buttonReset} component="span" disabled>
                                        {t("Account.reset")}
                                    </Button>
                                </label>
                                <div></div>
                            </>
                        </div>
                    </div>
                </div>

                <Divider className={classes.divider} />
                <div className={classes.buttonsWrapper}>
                    <Button
                        className={cx(classes.button, classes.buttonCancel)}
                        color="primary"
                        data-testid="profile-info-reset-button"
                        size="small"
                        variant="outlined"
                        onClick={() => reset()}
                    >
                        {t("App.cancel")}
                    </Button>
                    <PermissionTooltip isAllowed={isModifyAllowed}>
                        <Button
                            className={classes.button}
                            color="primary"
                            data-testid="profile-info-save-button"
                            disabled={isSubmitting}
                            size="small"
                            type="submit"
                            variant="contained"
                        >
                            {t("Account.save")}
                        </Button>
                    </PermissionTooltip>
                </div>
            </form>
        </div>
    );
};
